import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src045276070/src/variametrix-site-2021/src/templates/benefit.js";
export const query = graphql`
  {
    icons: file(name: { eq: "iconset--interface" }) {
      publicURL
    }
    banner: file(name: { eq: "banner--intuitive-interface" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64 
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BenefitBlurb = makeShortcode("BenefitBlurb");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BenefitBlurb title="Built for the many-hats" icon={props.data.icons.publicURL} iconOffset={0} mdxType="BenefitBlurb">
  <p>
    Whatever your marketing role, VariaMetrix makes it easier. From automated
    dashboards to channel attribution, VariaMetrix lets you see beyond the data
    and get you to the insights you need.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Annotate data, not charts" icon={props.data.icons.publicURL} iconOffset={1} mdxType="BenefitBlurb">
  <p>
    Data spikes happen, but that doesn't mean the whole team needs to
    investigate and lose a whole day of productivity. With VariaMetrix, one
    person can research and annotate the patterns they find and communicate
    those findings to everyone right within the charts. Insights are connected
    to the data itself, so when another user views a chart with the annotated
    data, they'll also see the annotation.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Atomicity on demand" icon={props.data.icons.publicURL} iconOffset={2} mdxType="BenefitBlurb">
  <p>
    VariaMetrix is the only tool that lets you set filters chart-by-chart or
    across the entire dashboard. If you want to see the same data over multiple
    timeframes on one screen, go ahead! Or lock a combination of charts to your
    global timescale and update them all at once. VariaMetrix puts you in total
    control of how your information is displayed.
  </p>
    </BenefitBlurb>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      